exports.components = {
  "component---src-pages-cookie-politika-js": () => import("./../../../src/pages/cookie-politika.js" /* webpackChunkName: "component---src-pages-cookie-politika-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-cookie-policy-js": () => import("./../../../src/pages/en/cookie-policy.js" /* webpackChunkName: "component---src-pages-en-cookie-policy-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-terms-of-use-js": () => import("./../../../src/pages/en/terms-of-use.js" /* webpackChunkName: "component---src-pages-en-terms-of-use-js" */),
  "component---src-pages-erabilera-baldintzak-js": () => import("./../../../src/pages/erabilera-baldintzak.js" /* webpackChunkName: "component---src-pages-erabilera-baldintzak-js" */),
  "component---src-pages-es-contacto-js": () => import("./../../../src/pages/es/contacto.js" /* webpackChunkName: "component---src-pages-es-contacto-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-politica-cookies-js": () => import("./../../../src/pages/es/politica-cookies.js" /* webpackChunkName: "component---src-pages-es-politica-cookies-js" */),
  "component---src-pages-es-politica-privacidad-js": () => import("./../../../src/pages/es/politica-privacidad.js" /* webpackChunkName: "component---src-pages-es-politica-privacidad-js" */),
  "component---src-pages-es-que-es-js": () => import("./../../../src/pages/es/que-es.js" /* webpackChunkName: "component---src-pages-es-que-es-js" */),
  "component---src-pages-es-terminos-de-uso-js": () => import("./../../../src/pages/es/terminos-de-uso.js" /* webpackChunkName: "component---src-pages-es-terminos-de-uso-js" */),
  "component---src-pages-fr-a-propos-js": () => import("./../../../src/pages/fr/a-propos.js" /* webpackChunkName: "component---src-pages-fr-a-propos-js" */),
  "component---src-pages-fr-contact-js": () => import("./../../../src/pages/fr/contact.js" /* webpackChunkName: "component---src-pages-fr-contact-js" */),
  "component---src-pages-fr-cookie-policy-js": () => import("./../../../src/pages/fr/cookie-policy.js" /* webpackChunkName: "component---src-pages-fr-cookie-policy-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-privacy-policy-js": () => import("./../../../src/pages/fr/privacy-policy.js" /* webpackChunkName: "component---src-pages-fr-privacy-policy-js" */),
  "component---src-pages-fr-terms-of-use-js": () => import("./../../../src/pages/fr/terms-of-use.js" /* webpackChunkName: "component---src-pages-fr-terms-of-use-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interfaze-berezia-js": () => import("./../../../src/pages/interfaze-berezia.js" /* webpackChunkName: "component---src-pages-interfaze-berezia-js" */),
  "component---src-pages-kontaktua-js": () => import("./../../../src/pages/kontaktua.js" /* webpackChunkName: "component---src-pages-kontaktua-js" */),
  "component---src-pages-pribatutasun-politika-js": () => import("./../../../src/pages/pribatutasun-politika.js" /* webpackChunkName: "component---src-pages-pribatutasun-politika-js" */),
  "component---src-pages-zer-da-js": () => import("./../../../src/pages/zer-da.js" /* webpackChunkName: "component---src-pages-zer-da-js" */)
}

